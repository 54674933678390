import React from "react";
/** @jsx jsx */
import { jsx, Grid } from "theme-ui";

const numberCircle = (number, opt_variant) => {
  if (!opt_variant) opt_variant = "light";
  let textColor;
  let backgroundColor;
  if (opt_variant === "light") {
    backgroundColor = "#1A395B";
    textColor = "#FFF";
  } else if (opt_variant === "dark") {
    backgroundColor = "#FFF";
    textColor = "#1A395B";
  }

  return (
    <div
      sx={{
        fontSize: "24px",
        textAlign: "center",
        lineHeight: "48px",
        height: "48px",
        width: "48px",
        fontWeight: 600,
      }}
    >
      <div
        sx={{
          backgroundColor: backgroundColor,
          color: textColor,
          borderRadius: "24px",
        }}
      >
        {number}
      </div>
    </div>
  );
};

export const HowItWorks = () => {
  return (
    <section className="aw-accent">
      <div className="aw-content">
        <h2>How it works</h2>
        <Grid columns={["48px auto"]}>
          {numberCircle(1)}
          <div>
            <h3 sx={{ marginTop: "5px" }}>Memorial Page</h3>
            <p>
              You’ll start by creating a memorial page for your someone. Our
              template helps you tell their story and share the service details
              with your guests.{" "}
            </p>
          </div>

          {numberCircle(2)}
          <div>
            <h3 sx={{ marginTop: "5px" }}>Planning Process</h3>
            <p>
              Next, you’ll plan the event using our software, including examples
              of what a program might be, templates you can use, and the ability
              to book a consultation with us to give inspiration and answer
              questions.
            </p>
          </div>

          {numberCircle(3)}
          <div>
            <h3 sx={{ marginTop: "5px" }}>Program Walkthrough</h3>
            <p>
              When you’ve finished planning, we join you for a walkthrough where
              we work with your speakers, test everyone’s technology, and review
              all of the plans for the event.
            </p>
          </div>

          {numberCircle(4)}
          <div>
            <h3 sx={{ marginTop: "5px" }}>Memorial Service</h3>
            <p>
              And then it’s time for the service. We’ll be there to facilitate
              your someone's Memorial and make sure everything goes smoothly.
            </p>
          </div>
        </Grid>
      </div>
    </section>
  );
};

export const HowItWorksWhatNow = (props) => {
  return HowItWorksBase({
    id: props.id,
    title: "HOW IT WORKS",
    variant: "dark",
    items: [
      {
        title: "Incorporate Your Existing Resources",
        description:
          "After sending us your bereavement resources and contact numbers, we digitize and translate the resources for your patient population. This process takes 1-2 weeks.",
      },
      {
        title: "Onboard Your Team",
        description:
          "We provide training and reference materials for your team. We host training sessions and attend staff meetings to demonstrate how to support families using Afterword.",
      },
      {
        title: "Launch to Your Patients & Families",
        description:
          "You’ll receive printed materials, QR codes, and quick links to share Afterword with your patients. Once patients start using Afterword, we share key insights with your team every 2-4 weeks.",
      },
    ],
  });
};

export const HowItWorksBase = ({ id, title, variant, items }) => {
  return (
    <section className={variant === "dark" ? "aw-bg-blue" : ""} id={id}>
      <div className="aw-content">
        {title && (
          <h2 sx={{ marginLeft: "calc(48px + 16px)" }}>
            {title || "How it works"}
          </h2>
        )}
        <Grid columns={["48px auto"]} sx={{ rowGap: 3 }}>
          {items.map((item, idx) => {
            return (
              <>
                {numberCircle(idx + 1, variant)}
                <div>
                  <h3
                    sx={{ marginTop: "0px", fontSize: 5, marginBottom: "0px" }}
                  >
                    {item.title}
                  </h3>
                  <p sx={{ fontSize: 3 }}>{item.description}</p>
                </div>
              </>
            );
          })}
        </Grid>
      </div>
    </section>
  );
};
